<template>
	<v-container fluid>
		<v-card class="pa-4">
			<v-form @submit.prevent="buscarCups">
				<v-row>
					<v-col cols="12" md>
						<v-text-field
							class="ml-4 flex-grow-1"
							filled
							label="CUPS"
							v-model="cups"
							hint="Lista de CUPS separada por coma"
							persistent-hint
							dense
							full-width
						/>
					</v-col>

					<v-col cols="12" md>
						<v-text-field
							class="ml-4 flex-grow-1"
							filled
							label="Código Postal"
							v-model="cp"
							dense
							hide-details
							full-width
						/>
					</v-col>

					<v-col cols="12" md>
						<v-text-field
							class="ml-4 flex-grow-1"
							filled
							label="Nombre Distribuidora"
							v-model="distName"
							dense
							hide-details
							full-width
						/>
					</v-col>

					<v-col cols="12" md>
						<v-text-field
							class="ml-4 flex-grow-1"
							filled
							label="Nombre tarifa"
							v-model="tarifa"
							dense
							hide-details
							full-width
						/>
					</v-col>

					<v-col cols="12" md>
						<div class="d-flex justify-end">
							<v-btn type="submit" color="primary" large :loading="loading">
								<v-icon color="white">mdi-magnify</v-icon>Buscar
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-form>
		</v-card>
		<v-row class="pa-1 my-1">
			<v-col cols="12" class="d-flex flex-wrap">
				<v-spacer />
				<v-btn
				@click.stop="exportarTodos(selected)"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>informacion general
			</v-btn>
				<v-btn
				@click.stop="exportarTodos(descargarConsumos())"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>consumos
			</v-btn>
				<!-- <download-csv
					:data="selected"
					:name="`informacion_sips_${new Date().getTime()}.csv`"
					delimiter=";"
				>
					<v-btn class="ma-1" color="primary" small :disabled="selected.length == 0">
						<v-icon left>mdi-download</v-icon>informacion general
					</v-btn>
				</download-csv> -->
				<!-- <download-csv
					:data="descargarConsumos()"
					:name="`consumos_sips_${new Date().getTime()}.csv`"
					delimiter=";"
				>
					<v-btn class="ma-1" color="primary" small :disabled="selected.length == 0">
						<v-icon left>mdi-download</v-icon>consumos
					</v-btn>
				</download-csv> -->
			</v-col>
		</v-row>

		<v-card>
			<v-data-table
				v-if="datos"
				item-key="CodigoCUPS"
				:items="datos.ClientesSips"
				:headers="$data[`clientesSipsHeaders${$store.getters.getEntorno}`]"
				show-select
				class="fixed-checkbox"
				v-model="selected"
				checkbox-color="secondary"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="datos.ClientesSips" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.tarifa="{ item }">
					<v-chip v-show="item.tarifa" label small>
						<s>{{ item.tarifaOld }}</s>
						<span>{{ item.tarifa }}</span>
					</v-chip>
				</template>

				<template v-slot:item.total="{ item }">
					<span v-show="item.total != null && item.total > 0" v-text="`${item.total} KWh`" />
				</template>
				<template v-slot:item.a1="{ item }">
					<span v-show="item.a1 != null && item.a1 > 0" v-text="`${item.a1} KWh`" />
				</template>
				<template v-slot:item.a2="{ item }">
					<span v-show="item.a2 != null && item.a2 > 0" v-text="`${item.a2} KWh`" />
				</template>
				<template v-slot:item.a3="{ item }">
					<span v-show="item.a3 != null && item.a3 > 0" v-text="`${item.a3} KWh`" />
				</template>
				<template v-slot:item.a4="{ item }">
					<span v-show="item.a4 != null && item.a4 > 0" v-text="`${item.a4} KWh`" />
				</template>
				<template v-slot:item.a5="{ item }">
					<span v-show="item.a5 != null && item.a5 > 0" v-text="`${item.a5} KWh`" />
				</template>
				<template v-slot:item.a6="{ item }">
					<span v-show="item.a6 != null && item.a6 > 0" v-text="`${item.a6} KWh`" />
				</template>

				<template v-slot:item.auxCaudalMedioEnWhAnual="{ item }">
					<span
						v-show="
							item.auxCaudalMedioEnWhAnual != null &&
							item.auxCaudalMedioEnWhAnual > 0
						"
						v-text="`${item.auxCaudalMedioEnWhAnual} KWh`"
					/>
				</template>
				<template v-slot:item.CaudalHorarioEnWh="{ item }">
					<span
						v-show="
							item.CaudalHorarioEnWh != null && item.CaudalHorarioEnWh > 0
						"
						v-text="`${item.CaudalHorarioEnWh} KWh`"
					/>
				</template>
				<template v-slot:item.CaudalMaximoDiarioEnWh="{ item }">
					<span
						v-show="
							item.CaudalMaximoDiarioEnWh != null &&
							item.CaudalMaximoDiarioEnWh > 0
						"
						v-text="`${item.CaudalMaximoDiarioEnWh} KWh`"
					/>
				</template>

				<template v-slot:item.FechaUltimoCambioComercializador="{ item }">
					<span v-text="parseDate(item.FechaUltimoCambioComercializador)"></span>
				</template>
				<template v-slot:item.FechaUltimoMovimientoContrato="{ item }">
					<span v-text="parseDate(item.FechaUltimoMovimientoContrato)"></span>
				</template>
				<template v-slot:item.FechaEmisionBIE="{ item }">
					<span v-text="parseDate(item.FechaEmisionBIE)"></span>
				</template>
				<template v-slot:item.PotenciaMaximaBIEW="{ item }">
					<span>{{ (item.PotenciaMaximaBIEW / 1000) }} kW</span>
				</template>

				<template v-slot:footer.prepend>
					<status-chip :value="$store.getters.getEntorno"></status-chip>
				</template>
			</v-data-table>
		</v-card>
		<portal-target name="filter-btn" />
	</v-container>
</template>

<script>
// import axios from "redaxios";
import {
	calcularConsumoAnual,
	calcularConsumoAnualGas,
	parseDate,
	perColumnFilter,
	obtenerNombreTarifaML,
	jsonToCsv,
	obtenerNombreTarifaGas,
} from "@/utils/index";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	data() {
		return {
			selected: [],

			cups: "",
			datos: { ClientesSips: [] },
			cp: "",
			distName: "",
			tarifa: "",
			loading: false,
			inlineFilters: {},

			clientesSipsHeadersLuz: [
				{ text: "CUPS", value: "CodigoCUPS", },
				{ text: "Municipio", value: "DesMunicipioPS", },
				{ text: "Municipio CP", value: "CodigoPostalPS", align: "end", },
				{ text: "Distribuidora", value: "NombreEmpresaDistribuidora", },
				{ text: "CodDistribuidora", value: "CodigoEmpresaDistribuidora", align: "end", },
				{ text: "Tarifa", value: "tarifa", dataType: "select", },
				{ text: "Consumo Total", value: "total", align: "end", },
				{ text: "Consumo P1 anual", value: "a1", align: "end", },
				{ text: "Consumo P2 anual", value: "a2", align: "end", },
				{ text: "Consumo P3 anual", value: "a3", align: "end", },
				{ text: "Consumo P4 anual", value: "a4", align: "end", },
				{ text: "Consumo P5 anual", value: "a5", align: "end", },
				{ text: "Consumo P6 anual", value: "a6", align: "end", },
				{ text: "P1", value: "PotenciasContratadasEnWP1", align: "end", },
				{ text: "P2", value: "PotenciasContratadasEnWP2", align: "end", },
				{ text: "P3", value: "PotenciasContratadasEnWP3", align: "end", },
				{ text: "P4", value: "PotenciasContratadasEnWP4", align: "end", },
				{ text: "P5", value: "PotenciasContratadasEnWP5", align: "end", },
				{ text: "P6", value: "PotenciasContratadasEnWP6", },
				{ text: "Potencia máxima instalación", value: "PotenciaMaximaBIEW", },
				{ text: "Último cambio comercializadora", value: "FechaUltimoCambioComercializador", },
				{ text: "Fecha emisión BIE", value: "FechaEmisionBIE", },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			clientesSipsHeadersGas: [
				{ text: "CUPS", value: "CodigoCUPS", },
				{ text: "Municipio", value: "DesMunicipioPS", },
				{ text: "Municipio CP", value: "CodigoPostalPS", align: "end", },
				{ text: "Distribuidora", value: "NombreEmpresaDistribuidora", },
				// { text: "CodDistribuidora", value: "CodigoEmpresaDistribuidora", align: "end", },
				{ text: "Tarifa", value: "tarifa", dataType: "select", },
				{ text: "CAE", value: "auxCaudalMedioEnWhAnual", align: "end", },
				{ text: "Caudal Horario", value: "CaudalHorarioEnWh", align: "end", },
				{ text: "Caudal Máximo", value: "CaudalMaximoDiarioEnWh", align: "end", },
				{ text: "Último cambio comercializadora", value: "FechaUltimoCambioComercializador", },
				{ text: "Último cambio BIE", value: "FechaEmisionBIE", },
				{ text: "Presión", value: "CodigoPresion", },
				{ text: "Último Cambio Contrato", value: "FechaUltimoMovimientoContrato", },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
		};
	},
	methods: {
		parseDate,
		buscarCups() {
			let list = false;
			if (this.cups && this.cups.includes(","))
				list = this.cups.split(",").length > 1;
			this.loading = true;
			axios({
				url:
					this.$store.getters.getEntorno == "Luz"
						? "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost"
						: "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSipsGas/GetClientesPost",
				method: "POST",
				data: {
					CodigoCUPS: list ? "" : this.cups,
					NombreEmpresaDistribuidora: this.distName,
					MunicipioPS: "",
					CodigoProvinciaPS: "",
					CodigoPostalPS: this.cp,
					CodigoTarifaATREnVigor: this.tarifa,
					ListCUPS: list ? this.cups.split(",") : "",
					LoadAllDatosCliente: true,
					LoadConsumos: true,
					IsExist: true,
				},
			}).then((res) => {
				res.data.ClientesSips = res.data.ClientesSips.map((cliente) => {
					let consumos;
					if (this.$store.getters.getEntorno == "Luz")
						consumos = calcularConsumoAnual(
							res.data.ConsumosSips,
							cliente.CodigoCUPS,
							cliente.CodigoTarifaATREnVigor
						);
					else
						consumos = calcularConsumoAnualGas(
							res.data.ConsumosSips,
							cliente.CodigoCUPS,
							cliente.CodigoTarifaATREnVigor
						);

					cliente.tarifa = this.obtenerNombreTarifaML(
						cliente.CodigoTarifaATREnVigor
					).actual;
					cliente.tarifaOld = this.obtenerNombreTarifaML(
						cliente.CodigoTarifaATREnVigor
					).antes;
					if (cliente.CodigoPeajeEnVigor)
						cliente.tarifa = obtenerNombreTarifaGas(cliente.CodigoPeajeEnVigor)
					if (
						"004" == cliente.CodigoTarifaATREnVigor ||
						"006" == cliente.CodigoTarifaATREnVigor
					) {
						cliente.PotenciasContratadasEnWP3 =
							cliente.PotenciasContratadasEnWP1;
						cliente.PotenciasContratadasEnWP2 = null;
					}

					return { ...cliente, ...consumos };
				});

				this.datos = {
					...res.data,
				};
				this.loading = false;

				return;
			});
		},
		obtenerNombreTarifaML,
		descargarConsumos() {
			let cups = this.selected.map(({ CodigoCUPS }) => {
				return { cups: CodigoCUPS };
			});
			let data = [];
			for (let i = 0; i < cups.length; i++) {
				data.push({
					cups: cups[i].cups,
					"Fecha Lectura Inicial": "",
					"Fecha Lectura Final": "",
					"P1 Activa": "",
					"P2 Activa": "",
					"P3 Activa": "",
					"P4 Activa": "",
					"P5 Activa": "",
					"P6 Activa": "",
					"P1 Reactiva": "",
					"P2 Reactiva": "",
					"P3 Reactiva": "",
					"P4 Reactiva": "",
					"P5 Reactiva": "",
					"P6 Reactiva": "",
					"P1 Maximetro": "",
					"P2 Maximetro": "",
					"P3 Maximetro": "",
					"P4 Maximetro": "",
					"P5 Maximetro": "",
					"P6 Maximetro": "",
				});
				data = [
					...data,
					...this.datos.ConsumosSips.filter(
						({ CodigoCUPS }) => CodigoCUPS == cups[i].cups
					).map((consumo) => {
						return {
							cups: "",
							"Fecha Lectura Inicial": consumo.FechaInicio,
							"Fecha Lectura Final": consumo.FechaFin,
							"P1 Activa": consumo.Activa1 || 0,
							"P2 Activa": consumo.Activa2 || 0,
							"P3 Activa": consumo.Activa3 || 0,
							"P4 Activa": consumo.Activa4 || 0,
							"P5 Activa": consumo.Activa5 || 0,
							"P6 Activa": consumo.Activa6 || 0,
							"P1 Reactiva": consumo.Reactiva1 || 0,
							"P2 Reactiva": consumo.Reactiva2 || 0,
							"P3 Reactiva": consumo.Reactiva3 || 0,
							"P4 Reactiva": consumo.Reactiva4 || 0,
							"P5 Reactiva": consumo.Reactiva5 || 0,
							"P6 Reactiva": consumo.Reactiva6 || 0,
							"P1 Maximetro": consumo.Potencia1 || 0,
							"P2 Maximetro": consumo.Potencia2 || 0,
							"P3 Maximetro": consumo.Potencia3 || 0,
							"P4 Maximetro": consumo.Potencia4 || 0,
							"P5 Maximetro": consumo.Potencia5 || 0,
							"P6 Maximetro": consumo.Potencia6 || 0,
						};
					}),
				];
			}
			return data;
		},
		exportarTodos(items) {
			const getDatatype = (key) =>
				([...this.clientesSipsHeadersLuz, ...this.clientesSipsHeadersGas].find((h) => h.value == key) || {}).dataType;

			if (!Array.isArray(items))
				items = this.datos.ClientesSips.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				);

			const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

			jsonToCsv(items, csvHeaders, 'SIPS_' + parseDate(Date.now(), false, false));
		},
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>